import Layout from '@/Layout/index.vue'
export default {
  path: '/business',
  name: 'business',
  meta: { title: '商家管理', hidden: false,requireAuth: true },
  auth_key: "business",
  component: Layout,
  children: [
    {
      path: 'entityList',
      name: 'entityList',
      auth_key:"entityList",
      meta: { title: '主体管理', hidden: false,requireAuth: true },
      component: () => import('@/views/entityManage/entity-list.vue')
    },
    {
      path: 'createEntity',
      name: 'createEntity',
      auth_key:"createEntity",
      hidden: true,
      meta: { title: '添加主体', hidden: true,requireAuth: true },
      component: () => import('@/views/entityManage/create-entity.vue') 
    },
    {
      path: 'entityInfo',
      name: 'entityInfo', 
      auth_key:"checkEntity",
      hidden: true,
      meta: { title: '主体详情', hidden: true,requireAuth: true },
      component: () => import('@/views/entityManage/entity-info.vue') 
    },
    {
      path: 'seniorList',
      name: 'seniorList',
      auth_key:"seniorList",
      meta: { title: '一级商家管理', hidden: false,requireAuth: true },
      component: () => import('@/views/senior/senior-list.vue')
    },
    {
      path: 'businessList',
      name: 'businessList',
      auth_key:"businessList",
      meta: { title: '二级商家管理', hidden: false,requireAuth: true },
      component: () => import('@/views/business/business-list.vue')
    },
    {
      path: 'quoted-price',
      name: 'quoted-price',
      auth_key:'quotedPriceList',
      hidden: true,
      meta: { title: '编辑报价', hidden: true,requireAuth: true },
      component: () => import('@/views/business/quoted-price.vue')
    }
  ]
} 