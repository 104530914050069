import Layout from '@/Layout/index.vue'
export default {
  path: '/order',
  name: 'order',
  auth_key: "order",
  meta: { title: '订单管理', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'all',
      name: 'all',
      auth_key: "allOrder",
      meta: { title: '全部订单', hidden: false,requireAuth: true },
      component: () => import('@/views/order/all.vue')
    },
    {
      path: 'ledger',
      name: 'ledger',
      auth_key: "ledgerOrder",
      meta: { title: '分账订单', hidden: false,requireAuth: true },
      component: () => import('@/views/order/ledger.vue')
    },
    {
      path: 'detail',
      name: 'detail',
      auth_key: "orderDetail",
      hidden: true,
      meta: { title: '订单详情', hidden: true,requireAuth: true },
      component: () => import('@/views/order/detail.vue')
    },
    {
      path: 'stageList',
      name: 'stageList',
      auth_key: "stageList",
      meta: { title: '分期账单列表', hidden: false,requireAuth: true },
      component: () => import('@/views/order/stage-list.vue')
    },
    {
      path: 'prewviewDetail',
      name: 'prewviewDetail',
      auth_key: "prewviewDetail",
      meta: { title: '预览账单合同', hidden: false,requireAuth: true },
      component: () => import('@/views/order/prewviewDetail.vue')
    },
    {
      path: 'prewviewAgreement',
      name: 'prewviewAgreement',
      auth_key: "prewviewAgreement",
      meta: { title: '课程协议和赊销协议', hidden: false,requireAuth: true },
      component: () => import('@/views/order/prewviewAgreement.vue')
    },
    {
      path: 'lawyer',
      name: 'lawyer',
      auth_key: "lawyer",
      meta: { title: '律师函', hidden: false,requireAuth: true },
      component: () => import('@/views/order/lawyer.vue')
    }
  ]
} 
