import request from '@/utils/request'


export function Login (params) {
  return request.post('/api/login', params)
}

export function logout (params) {
  return request.post('/api/logout', params)
}
