import Layout from '@/Layout/index.vue'
export default {
  path: '/productManage',
  name: 'productManage',
  redirect: '/productManage/productManage',
  auth_key: "h5UserManage",
  meta: { title: '产品管理', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'productManage',
      name: 'productManage',
      auth_key: "h5UserList",
      meta: { title: '配置首页助梦金', hidden: false,requireAuth: true },
      component: () => import('@/views/productManage/productManage.vue')
    }
  ]
}
