import Layout from '@/Layout/index.vue'
export default {
  path: '/business-auth-configuration',
  name: 'business-auth-configuration',
  auth_key:"businessAuthConfiguration",
  meta: { title: '商家账号管理', hidden: false,requireAuth: true },
  component: Layout,
  alwaysShow: true,
  children: [
    {
      auth_key:'businessAdminRole',
      path: 'businessAdminRole',
      name: 'businessAdminRole',
      meta: { title: '角色管理', hidden: false,requireAuth: true },
      component: () => import('@/views/businessAuthConfiguration/business-admin-role.vue')
    },
    {
      path: 'listBusinessAdmin',
      name: 'listBusinessAdmin',
      auth_key:'listBusinessAdmin',
      meta: { title: '账号管理', hidden: false,requireAuth: true },
      component: () => import('@/views/businessAuthConfiguration/business-admin-list.vue')
    },
  ]
} 
