import Layout from '@/Layout/index.vue'
export default {
  path: '/h5UserManage',
  name: 'h5UserManage',
  redirect: '/h5UserManage/h5UserList',
  auth_key: "h5UserManage",
  meta: { title: '用户管理', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'h5UserList',
      name: 'h5UserList',
      auth_key: "h5UserList",
      meta: { title: '用户列表', hidden: false,requireAuth: true },
      component: () => import('@/views/h5UserManage/h5-user-list.vue')
    }
  ]
}
