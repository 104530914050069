import Layout from '@/Layout/index.vue'
export default {
  path: '/product',
  name: 'product',
  meta: { title: '商家产品', hidden: false,requireAuth: true },
  auth_key: "product",
  hidden: true,
  component: Layout,
  children: [
    {
      path: 'productList',
      name: 'productList',
      auth_key:"productList",
      hidden: true,
      meta: { title: '商家产品列表', hidden: false,requireAuth: true },
      component: () => import('@/views/product/product-list.vue')
    },
    {
      path: 'productModal',
      name: 'productModal',
      auth_key:"productModal",
      hidden: true,
      meta: { title: '商家产品详情', hidden: true,requireAuth: true },
      component: () => import('@/views/product/components/product-modal.vue') 
    }
  ]
} 