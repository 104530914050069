import { render, staticRenderFns } from "./AppMain.vue?vue&type=template&id=4b6f3815&scoped=true&"
import script from "./AppMain.vue?vue&type=script&lang=js&"
export * from "./AppMain.vue?vue&type=script&lang=js&"
import style0 from "./AppMain.vue?vue&type=style&index=0&id=4b6f3815&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b6f3815",
  null
  
)

export default component.exports