import Layout from '@/Layout/index.vue'
export default {
  path: '/user',
  name: 'user',
  redirect: '/user/userList',
  auth_key: "userMange",
  meta: { title: '后台账号管理', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'rolemanagement',
      name: 'rolemanagement',
      auth_key: "roleList",
      meta: { title: '角色管理', hidden: false ,requireAuth: true},
      component: () => import('@/views/user/role-management.vue')
    },
    {
      path: 'list',
      name: 'list',
      auth_key: "userList",
      meta: { title: '账号管理', hidden: false,requireAuth: true },
      component: () => import('@/views/user/user-list.vue')
    },
    {
      path: 'manage',
      name: 'manage',
      auth_key: "usermeage",
      hidden: true,
      meta: { title: '账号详情', hidden: true,requireAuth: true },
      component: () => import('@/views/user/user-meage.vue')
    }
  ]
}
