<template>
  <el-aside :class="{ collapse: isCollapse }">
    <el-menu
      mode="horizontal"
      :default-active="$route.name"
      :collapse="true"
    >
      <div class="logos">
        <h2 class="title"><span v-if="ENV_TEST">[TEST] </span>蚂蚁助学</h2>
      </div>
      <SlideBarItem class="SlideBarItem" v-for="item in authMenus" :key="item.path" :item="item" />
      <div class="userInfo">
        <el-dropdown @visible-change="changeDropdown" @command="handleCommand">
          <span class="el-dropdown-link"
            >{{ username
            }}<i
              style="padding-left: 10px"
              :class="[icondrop ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"
            ></i
          ></span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="个人中心">个人中心</el-dropdown-item> -->
            <el-dropdown-item command="退出登录">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-menu>
  </el-aside>
</template>

<script>
import SlideBarItem from "./Item.vue";
import { logout } from "@/api/login.js";
import config from "@/utils/config.js";
export default {
  name: "",
  components: { SlideBarItem },
  data() {
    return {
      icondrop: false,
      authMenus: [],
      ENV_TEST: config.ENV_TEST,
    };
  },
  computed: {
    isCollapse() {
      // return this.$store.getters.isCollapse;
      return false
    },
    routes() {
      return this.$store.getters.routes;
    },
    username() {
      return localStorage.getItem("username");
    },
  },
  mounted() {
    var menu = this.$store.getters.routes;
    var authMenu = localStorage.getItem("auth");
    var managerType = localStorage.getItem("managerType");
    let authArr = [];
    try {
      authArr = JSON.parse(authMenu);
      this.authMenus = [];
      if (managerType == 0) {
        var authKeyList = [];
        authArr.map((item) => {
          authKeyList.push(item.auth_key);
        });
        const filterMenu = (menuList, menuCode) => {
          return menuList
            .filter((item) => {
              return menuCode.indexOf(item.auth_key) > -1;
            })
            .map((item) => {
              item = Object.assign({}, item);
              if (item.children) {
                item.children = filterMenu(item.children, menuCode);
              }
              return item;
            });
        };
        this.authMenus = filterMenu(menu, authKeyList);
      } else if (managerType == 1) {
        this.authMenus = this.$store.getters.routes;
      } else if (managerType == 2) {
        var adMenu = [];
        this.$store.getters.routes.map((item) => {
          if (item.auth_key != "authConfiguration") {
            adMenu.push(item);
          }
        });
        this.authMenus = adMenu;
      }
    } catch (e) {
      console.log("发生异常", e);
    }
  },
  methods: {
    changeDropdown(bool) {
      this.icondrop = bool;
    },
    handleCommand(command) {
      if (command == "个人中心") {
        console.log("个人中心");
      } else if (command == "退出登录") {
        logout().then((res) => {
          if (res.code == 200) {
            localStorage.clear();
            this.$router.replace("/login");
          }
        });
        // const res = await logout()
        // console.log(res)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-aside {
  width: 100% !important;
  // min-width: 1000px !important;
  height: 60px !important;
  background: linear-gradient(90deg, #1d42ab, #2173dc, #1e93ff);
  /deep/ .el-menu {
    width: 100% !important;
    background-color: transparent;
    padding: 0 26px;
    border: none;
    display: flex;
    overflow-x: auto;
    // .SlideBarItem{
    //   flex: 1;
    // }
    .userInfo {
      min-width: 100px;
      flex: 1;
      height: 60px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      span {
        color: #fff;
      }
    }
    .logos {
      height: 60px;
      color: #fff;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      .title {
        min-width: 200px;
        line-height: 45px;
        font-size: 26px;
      }
    }
    .el-submenu {
      float: left;
      &.is-active {
        .el-submenu__title {
          background-color: transparent;
          color: #fff;
          i {
            color: #fff;
          }
        }
      }
      .el-submenu__title {
        color: #bdd2f1;
        &:hover {
          background-color: transparent;
          color: #fff;
          i {
            color: #fff;
          }
        }
        > i {
          color: #bdd2f1;
        }
      }
    }

    .el-menu-item {
      float: left;
      &.is-active,
      &:hover {
        background-color: transparent;
        color: #fff;
        .el-submenu__title,
        a,
        i {
          color: #fff;
          background-color: transparent;
        }
      }
      .el-submenu__title,
      a,
      i {
        color: #bdd2f1;
        &:hover {
          &,
          i {
            color: #fff;
          }
        }
      }
    }
  }
  // /deep/ .el-menu-item > a > span,
  // /deep/ .el-submenu__title > span {
  //   position: relative;
  //   right: 0;
  //   transition: right 1s;
  // }
  // &.collapse {
  //   width: 64px !important;
  //   /deep/ .el-menu-item > a > span,
  //   /deep/ .el-submenu__title > span {
  //     right: -100px;
  //   }
  // }
}
</style>
