import Layout from '@/Layout/index.vue'
export default {
  path: '/auth-configuration',
  name: 'auth-configuration',
  auth_key:"authConfiguration",
  meta: { title: '开发配置', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'authList',
      name: 'authList',
      auth_key:'authList',
      meta: { title: '平台后台权限列表', hidden: false,requireAuth: true },
      component: () => import('@/views/authConfiguration/auth-list.vue')
    },
    {
      auth_key:'portList',
      path: 'portList',
      name: 'portList',
      meta: { title: '平台后台接口列表', hidden: false,requireAuth: true },
      component: () => import('@/views/authConfiguration/port-list.vue')
    },
    {
      path: 'businessAuthList',
      name: 'businessAuthList',
      auth_key:'businessAuthList',
      meta: { title: '商家后台权限列表', hidden: false,requireAuth: true },
      component: () => import('@/views/businessAuthConfiguration/business-auth-list.vue')
    },
    {
      auth_key:'businessPortList',
      path: 'businessPortList',
      name: 'businessPortList',
      meta: { title: '商家后台接口列表', hidden: false,requireAuth: true },
      component: () => import('@/views/businessAuthConfiguration/business-port-list.vue')
    },
  ]
} 
