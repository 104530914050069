import Layout from '@/Layout/index.vue'
export default {
  path: '/data-summarization',
  name: 'data-summarization',
  auth_key:"dataSummarization",
  meta: { title: '数据汇总', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'contractDataSummarization',
      name: 'contractDataSummarization',
      auth_key:'contractDataSummarization',
      meta: { title: '签约数据汇总', hidden: false,requireAuth: true },
      component: () => import('@/views/dataSummarization/contract-data-summarization.vue')
    },
    {
      path: 'repaymentDataSummarization',
      name: 'repaymentDataSummarization',
      auth_key:'repaymentDataSummarization',
      meta: { title: '还款数据汇总', hidden: false,requireAuth: true },
      component: () => import('@/views/dataSummarization/repayment-data-summarization.vue')
    },
    {
      path: 'ledgerDataSummarization',
      name: 'ledgerDataSummarization',
      auth_key:'ledgerDataSummarization',
      meta: { title: '机构数据分账汇总', hidden: false,requireAuth: true },
      component: () => import('@/views/dataSummarization/ledger-data-summarization.vue')
    },
    {
      path: 'monthNotSummarization',
      name: 'monthNotSummarization',
      auth_key:'monthNotSummarization',
      meta: { title: '月度未还汇总', hidden: false,requireAuth: true },
      component: () => import('@/views/dataSummarization/month-not-summarization.vue')
    },
    {
      path: 'monthAlreadySummarization',
      name: 'monthAlreadySummarization',
      auth_key:'monthAlreadySummarization',
      meta: { title: '月度已还汇总', hidden: false,requireAuth: true },
      component: () => import('@/views/dataSummarization/month-already-summarization.vue')
    },
    
  ]
} 
